html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
}

.nav-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

footer {
    width: 100% !important;
    position:absolute;
    background-color: #f3f3f3;
    min-width: 500px;
}

.site-footer {
    background-color: #f3f3f3;
}

.fa:hover {
    color: black;
    opacity: 0.7;
    text-decoration: none;
}

.fa {
    color: grey;
}

.navbar {
    background-color: transparent !important;
}

.wrapper-no-pt {
	  position: relative;
	  width: 800px;
	  margin: auto;
    min-width: 500px;
}

.wrapper {
	  position: relative;
	  padding-top: 10px;
	  width: 800px;
	  margin: auto;
    min-width: 500px;
}

@media screen and (max-device-width: 800px) {
    .wrapper {
        width: 100%;
        padding-left: ($spacer * .5) !important;
        padding-right: ($spacer * .5) !important;
        min-width: 500px;
    }

    .wrapper-no-pt {
        width: 100%;
        padding-left: ($spacer * .5) !important;
        padding-right: ($spacer * .5) !important;
        min-width: 500px;
    }
}

/* Useful CSS snippet for debugging left and right padding */
/* * { */
/*     background: #000 !important; */
/*     color: #0f0 !important; */
/*     outline: solid #f00 1px !important; */
/* } */
